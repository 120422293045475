<template>
    <BT-Blade-Items
        addBladeName="automatic-line-item"
        :bladesData="bladesData"
        bladeName="automatic-line-items"
        canAdd
        canDelete
        :headers="[
            { text: 'Line Name', value: 'lineItemName', title: 1, searchable: true },
            { text: 'Description', value: 'description', title: 1, searchable: true }]"
        navigation="automatic-line-items"
        title="Automatic Lines"
        useServerPagination />
</template>

<script>
export default {
    name: 'Automatic-Line-Items-Blade',
    props: {
        bladesData: null
    }
}
</script>